	import axios from 'axios';
  

  /* DEV  */
  //export const API_UR = 'http://192.168.1.161:8000/api';

  /*  PROD  */
  export const API_UR = 'http://apireinscripciones.cbtis41.net/api';

 
export const request = axios.create({
	baseURL 		: API_UR,
	responseType 	: 'json',
});


export const request_file = () => {

	return axios.create({
		baseURL 		: API_UR,
		responseType 	: 'blob',
		headers 		: {'Content-Type': 'multipart/form-data', }

	});

}

