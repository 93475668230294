import React, { Component } from 'react';
import { Form, Row, Col } from 'reactstrap';
import { request, request_file } from './request.js';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import './App.css';
import './fonts/css/open-iconic.css';
import 'animate.css';
import imagen from './imagenes/hombre.png';

class App extends Component 
{

	constructor(props)
	{
		super(props)
		this.state = {
			codigo : "",
			acceso : 
			{
				pass : '',
				auth : 0,
			},
			registro :
			{
				num_control : '',
				tipo : '',
				grupo : '',
				apellido1 : '',
				apellido2 : '',
				nombre : '',
				hora : '',
			},
			movimientos : [],
			foto : '',
			conectado : false
		}
	}

	componentDidMount()
	{
	
		var { conectado, foto } = this.state;
		let temp = this;

		request.get(`/ProbarConexion`)
		.then(function(response)
		{
			if(response.status === 200)
			{
				conectado = true;
	 			temp.setState({ conectado : conectado });
		 	}
		})
		.catch(error => {} );

		foto=imagen;

		this.setState({ conectado : conectado, foto : foto });
	}

	Recargar=()=> { window.location.reload(false); }

	submit=(evt)=>
	{
		evt.preventDefault();
		var { codigo } = this.state;
		this.RegistrarEntrada(codigo);
		codigo="";
		this.setState({ codigo : codigo });
	}

	submitPass=(evt)=>
	{
		evt.preventDefault();
		this.RevisarPassword();
	}

	RevisarPassword=()=>
	{
		var { acceso } = this.state;
		let temp = this;
		var datos;
		request.post('/RevisarPassword',acceso)
		.then(function(response)
		{
			if(response.status === 200)
			{
				datos=response.data;
				acceso['auth']=datos['auth'];
				acceso['pass']="";
				temp.setState({ acceso : acceso });
			}
		})
		.catch(error => {});
		
		this.setState({ acceso : acceso });
	}

	RegistrarEntrada=(cod)=>
	{
		var { registro, movimientos, foto } = this.state;
		var arreglo = movimientos;
		let temp = this;

		if(cod === "0011468079")
		{
			this.Recargar();
		}
		else
		{
			request.get(`/RegistrarMovimiento/${cod}`)
			.then(function(response)
			{
				if(response.status === 200)
				{
					if(response.data)
					{
			 			registro = response.data;
			 			arreglo = [registro, ...arreglo];
			 			temp.setState({ registro : registro, movimientos : arreglo });
			 		}
			 		else
			 		{
		 				Swal.fire({
							position: 'top-end',
							icon: 'error',
							title: 'No Registrado',
							toast: true,
							showConfirmButton: false,
							timer: 1500
						});
			 			temp.setState({ registro : registro });
			 		}
			 	}
			})
			.catch(error => {} );

			foto=imagen;

			request_file().post(`/MostrarImagen?codigo=${cod}`)
			.then(response =>
			{
				if(response.data)  
				{
					const file = new Blob([response.data], {type: 'image/jpeg' });
					if(file.size !== 0)
					{
						const fileURL = URL.createObjectURL(file);
						foto=fileURL;
						temp.setState({ foto : foto });
					}
				}
			})
			.catch(error => { });

			this.setState({ foto : foto });
		}
	}

	handleInputChange=(event)=>
	{
		const value = event.target.value;
		var { codigo } = this.state;
		codigo = value;
		this.setState({ codigo : codigo });
	}

	handleInputChangePass=(event)=>
	{
		const value = event.target.value;
		var { acceso } = this.state;
		acceso['pass'] = value;
		this.setState({ acceso : acceso });
	}

	render()
	{
		let { conectado, movimientos, foto, codigo, acceso } = this.state;

		return (
			<div className="body">
				{
					parseInt(acceso.auth,10) === 0?
						<div className="main">
							<Row className="pl-1 pr-1">
								<Col className="col-12">
									<Form onSubmit={this.submitPass}>
										<Row>
											<Col>
												<div className="pl-1 pr-1 input-group mb-1 loginForm">
													<span className="input-group-text" id="basic-addon1"> <span className="oi ver" data-glyph="cog" aria-hidden="true"> </span> &nbsp; Codigo de acceso: </span>
													<input type="password" autoFocus autoComplete="off" className="form-control" placeholder="" value={acceso.pass} aria-label="pass" name="pass" id="pass" aria-describedby="basic-addon1" onChange={this.handleInputChangePass} />
													<span className="input-group-text">CBTIS #41</span>
												</div>
											</Col>
										</Row>
									</Form>
								</Col>
							</Row>	
						</div>
					:
						<div className="main">
							<Row className="pl-1 pr-1">
								<Col className="col-12">
									<Form onSubmit={this.submit}>
										<Row>
											<Col>
												<div className="pl-1 pr-1 input-group mb-1 loginForm">
													<span className="input-group-text" id="basic-addon1"> Registro de entrada &nbsp; <span className="oi ver" data-glyph="cog" aria-hidden="true"> </span> </span>
													<input type="password" autoFocus autoComplete="off" className="form-control" placeholder="" value={codigo} aria-label="codigo" name="codigo" id="codigo" aria-describedby="basic-addon1" onChange={this.handleInputChange} />
													<span className="input-group-text">CBTIS #41</span>
												</div>
											</Col>
										</Row>
									</Form>
						
									{/* CUADRO DE FOTO */}					
									<Row>
										<Col>
											<div className="pl-2 pr-2 mb-1 loginForm">
												<table>
													<tbody>
														<tr className="f ml-2">
															<td className="f">
																<img className="cuadroFoto" align="middle" src={foto} alt="..."/>
															</td>

															<td className="datos">
																<span className="ne">No. De Control:</span>
																<br/>
																{
																	movimientos.length>0? 
																		<span className="ne b pl-3"> {movimientos[0].num_control} </span>
																	:
																		""
																}
																<br/>
																<span className="ne"> Apellido Paterno:</span>
																<br/>
																{
																	movimientos.length>0? 
																		<span className="ne b pl-3"> {movimientos[0].apellido_paterno} </span>
																	:
																		""
																}
																<br/>
																<span className="ne"> Apellido Materno:</span>
																<br/>
																{
																	movimientos.length>0? 
																		<span className="ne b pl-3"> {movimientos[0].apellido_materno} </span>
																	:
																		""
																}
																<br/>
																<span className="ne"> NOMBRE(s): </span>
																<br/>
																{
																	movimientos.length>0? 
																		<span className="ne b pl-3"> {movimientos[0].nombre} </span>
																	:
																		""
																}
																<br/>
																<span className="ne"> GRUPO: </span>
																{
																	movimientos.length>0? 
																		<span className="ne b pl-1"> {movimientos[0].grupo} </span>
																	:
																		""
																}
																<br/>
															
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</Col>
									</Row>
										
									{/* LISTA DE REPORTE */}
									<Form>
										<Row>
											<Col>
												<div className="pl-2 pr-2 loginForm maxh lista mb-2">
												{
													conectado ?

														<table className="table table-responsive-sm table-striped table-bordered table-sm">
															<tbody>
															{
																movimientos.map((item, i) => 
																	<tr key={i}>
																		<td className="cm cen ne"> { movimientos.length-i } </td>
																		{
																			parseInt(item.tipo)===1 ?
																				<td className="cc cen"> <span className="oi ve" data-glyph="arrow-thick-top" aria-hidden="true"></span> </td>
																			:
																				<td className="cc cen"> <span className="oi ro" data-glyph="arrow-thick-bottom" aria-hidden="true"></span> </td>
																		}
																		{
																			//parseInt(item.mensaje)===0 ?
																				<td className="cc cen"> <span className="oi ro" data-glyph="envelope-open" aria-hidden="true"></span> </td>
																			//:
																			//	<td className="cc cen"> <span className="oi ve" data-glyph="envelope-open" aria-hidden="true"></span> </td>
																		}
																		<td className="cm cen ne"> {item.grupo} </td>
																		<td className="cx ne"> {item.apellido_paterno} {item.apellido_materno} , {item.nombre} </td>
																		<td className="cg cen ne"> {item.hora} </td>
																	</tr>
																)
															}
															</tbody>
														</table>
													:
														<span className="oi ro" data-glyph="signal" aria-hidden="true"> No hay conexión a internet </span>
												}					
												</div>
											</Col>
										</Row>
									</Form>
									
								</Col>
							</Row>
						</div>
				}
			</div>
		);
	}
}

export default App;